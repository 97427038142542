import React, { FC } from 'react'

import { formatDate, parseDescription } from '../../shared/blog-utils'

import './BlogPosts.scss'

interface Props {
  posts: Array<{
    author: string
    categories: Array<string>
    content: string
    description: string
    guid: string
    link: string
    pubDate: string
    thumbnail: string
    title: string
  }>
}

const BlogPosts: FC<Props> = ({ posts }) => {
  return (
    <ul className="blog-list">
      {posts.map((post, index) => (
        <li key={index}>
          <div style={{ backgroundImage: `url(${post.thumbnail})` }}></div>
          <div className="blog-info">
            <h3>
              <a href={post.link} target="_blank" rel="noreferrer">
                {post.title}
              </a>
            </h3>
            <div className="description">
              {parseDescription(post.description)}
            </div>
            <div className="date">{formatDate(post.pubDate)}</div>
            <a
              href={post.link}
              target="_blank"
              className="read"
              rel="noreferrer"
            >
              Read
            </a>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default BlogPosts
