const removeTags = (html) => {
  const div = document.createElement('div')
  div.innerHTML = html

  return div.textContent || div.innerText || ''
}

const parseDescription = (description: string): string => {
  const descriptionText = removeTags(description)
  const limit = 150

  return descriptionText.length > limit
    ? descriptionText.substring(0, limit) + '...'
    : descriptionText
}

const formatDate = (date: string): string => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(new Date(date))
}

export { parseDescription, formatDate }
