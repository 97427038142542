import React, { FC } from 'react'
import Layout from '../components/Layout'
import Blog from '../components/Blog'

const BlogPage: FC = () => {
  return (
    <Layout title="Blog">
      <Blog />
    </Layout>
  )
}

export default BlogPage
