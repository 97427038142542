import React, { FC, useEffect, useState } from 'react'
import Axios from 'axios'

import BlogPosts from '../BlogPosts'

import './Blog.scss'

const Blog: FC = () => {
  const [posts, setPosts] = useState([])
  useEffect(() => {
    loadPosts()
  }, [])

  const loadPosts = () => {
    const mediumURL =
      'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@hackernoon'

    Axios.get(mediumURL)
      .then((res) => setPosts(res.data.items || []))
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <div className="content blog-page">
      <h1>Blog</h1>
      <h2>Read our latest stories from Medium</h2>
      {posts && <BlogPosts posts={posts} />}
    </div>
  )
}

export default Blog
